<template>
  <div class="main__content">
    <b-card
      v-if="datesLists.length"
      no-body
      class="p-1"
    >
      <!-- <b-row>
        <b-col cols="12">
          <div class="d-flex flex-row pt-0 pb-1 pt-sm-0 pb-sm-1 pt-md-1 pb-md-1">
            <div
              class="pr-1"
            >
              <b-form-input
                v-model.trim="searchUsernameValue"
                :placeholder="$t('Username')"
                maxlength="32"
              />
            </div>
            <div>
              <Button
                variant="primary"
                icon="SearchIcon"
                :loading="$wait.is('loading-search')"
                :text="$t('Search')"
              />
            </div>
          </div>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex">
            <div
              class="dates-search pr-1"
            >
              <BillingDates
                :dates="datesLists"
                @onChangeDateSelected="onChangeDateSelected"
              />
            </div>
            <div class="dates-search">
              <Button
                variant="primary"
                icon="SearchIcon"
                :loading="$wait.is('loading-reports')"
                @click="onReload"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="12">
          <div class="d-flex justify-content-end align-items-center">
            <div>
              <VueGoodTableSwitchCompactMode />
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- select  -->
      <b-row v-if="selectEnabled">
        <b-col cols="12">
          <div class="table__action d-flex flex-row py-1">
            <div class="pr-1">
              <Button
                variant="primary"
                size="sm"
                icon="CheckIcon"
                :loading="$wait.is('loading-button-confirm')"
                :text="$t('Paid')"
                @click.prevent.stop="onConfirmPaid"
              />
            </div>
            <div>
              <Button
                variant="danger"
                size="sm"
                icon="XIcon"
                :loading="$wait.is('loading-button-confirm')"
                :text="$t('Pending')"
                @click.prevent.stop="onConfirmPending"
              />
            </div>
          </div>

          <div
            class="table__selected d-flex"
          >
            <div>
              <span v-if="selectedRowsTotal > 0 && selectedRowsTotal === dataLists.length">{{ $t('Item Selected') }} : {{ $t('All') }}</span>
              <span v-else>{{ $t('Item Selected') }} : {{ selectedRowsTotal }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-else
      no-body
      class="p-1"
    >
      <Loading />
    </b-card>
    <!-- table -->
    <div
      v-if="datesLists.length"
      class="table__list"
    >
      <VueGoodTableRemote
        ref="tableData"
        :columns-top="columnsTop"
        :columns="columns"
        :rows="dataLists"
        :line-numbers="true"
        theme="black-rhino"
        :is-loading="$wait.is('loading-reports') || $wait.is('loading-dates')"
        :pagination-enabled="dataLists.length > 3000"
        :total-records="dataLists.length"
        table-mode="any"
        :select-enabled="selectEnabled"
        @selectionChanged="selectionChanged"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="FieldNumberLists.some(list => list === props.column.field)">
            <span
              class="text-total"
              :class="[Number(props.formattedRow[props.column.field]) < 0 ? 'red--text' : 'black--text']"
            >{{ DecimalFormat(props.formattedRow[props.column.field]) }}</span>
          </span>
          <div
            v-else-if="props.column.field === '_id'"
          >
            <span
              class="link--text"
            >{{ props.formattedRow[props.column.field] }}</span>
          </div>
          <div
            v-else-if="props.column.field === 'stackCount'"
          >
            <span
              class="text-total black--text"
            >{{ DecimalFormatCustomFix(props.formattedRow[props.column.field], 0) }}</span>
          </div>
          <div
            v-else-if="props.column.field === 'paid'"
          >
            <b-badge
              v-if="String(props.formattedRow[props.column.field]) === 'true'"
              href="#"
              variant="success"
              class="list__status"
            >
              {{ $t('Paid') }}
            </b-badge>
            <b-badge
              v-else
              href="#"
              variant="danger"
              class="list__status"
            >
              {{ $t('Pending') }}
            </b-badge>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- summary -->
        <template slot="table-actions-bottom2">
          <tr class="tr-on-hover">
            <td
              :colspan="getColumnSpanTotal.tableActionBottom"
              class="text-center black--text text-total"
            >
              <span>{{ $t('Summary') }}</span>
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.amount) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.amount) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.validAmount) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.validAmount) }}
            </td>
            <td
              class="text-right black--text text-total"
            >
              {{ summary.stackCount }}
            </td>

            <!-- member -->
            <td
              class="text-right text-total"
              :class="[Number(summary.memberWinLose) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.memberWinLose) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.memberWinLoseCom) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.memberWinLoseCom) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.memberTotalWinLoseCom) < 0 ? 'red--text' : 'black--text', bgTotalCom]"
            >
              {{ DecimalFormat(summary.memberTotalWinLoseCom) }}
            </td>
            <!-- end member -->

            <!-- agent -->
            <td
              class="text-right text-total"
              :class="[Number(summary.agentWinLose) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.agentWinLose) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.agentWinLoseCom) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.agentWinLoseCom) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.agentTotalWinLoseCom) < 0 ? 'red--text' : 'black--text', bgTotalCom]"
            >
              {{ DecimalFormat(summary.agentTotalWinLoseCom) }}
            </td>
            <!-- end agent -->

            <!-- company -->
            <td
              class="text-right text-total"
              :class="[Number(summary.companyWinLose) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.companyWinLose) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.companyWinLoseCom) < 0 ? 'red--text' : 'black--text']"
            >
              {{ DecimalFormat(summary.companyWinLoseCom) }}
            </td>
            <td
              class="text-right text-total"
              :class="[Number(summary.companyTotalWinLoseCom) < 0 ? 'red--text' : 'black--text', bgTotalCom]"
            >
              {{ DecimalFormat(summary.companyTotalWinLoseCom) }}
            </td>
            <!-- end company -->

          </tr>
        </template>

      </VueGoodTableRemote>
    </div>
  </div>
</template>

<script>
import { AgentTypes, IsReportUpline } from '@/utils/agent'
import { DecimalFormat, DecimalFormatCustomFix } from '@/utils/number'
import VueGoodTableRemote from '@/components/VueGoodTableRemote.vue'
import BillingDates from '@/components/billing/BillingDates.vue'
import Button from '@/components/Button.vue'
import Loading from '@/components/LoadingComponent.vue'
import store from '@/store'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'

export default {
  components: {
    VueGoodTableRemote,
    VueGoodTableSwitchCompactMode,
    BillingDates,
    Button,
    Loading,
  },
  props: {
    selectEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    datesLists: {
      type: Array,
      required: true,
    },
    dataLists: { type: Array, required: true },
    summary: { type: Object, required: true },
    isMyBilling: { type: Boolean, required: true },
    isMember: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selectedRowsTotal: 0,
      selectedRows: [],

      searchUsernameValue: '',

      // datesLists: [],
      selectedDate: null,

      // lists: [],
      // summary: {},

    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    bgTotalCom() {
      return 'table__tdclass__lists'
    },
    getColumnSpanTotal() {
      if (this.isMember) {
        return {
          tableActionBottom: 2,
          headerTop: 4,
        }
      }
      if (this.isMyBilling) {
        return {
          tableActionBottom: 3,
          headerTop: 5,
        }
      }

      return {
        tableActionBottom: 4,
        headerTop: 6,
      }
    },
    columnsTop() {
      const headers = [
        {
          colspan: this.getColumnSpanTotal.headerTop,
        },
        {
          label: this.$t('Member'),
          colspan: 3,
          style: {
            'text-align': 'center',
          },
        },
        {
          label: this.$t('Agent'),
          colspan: 3,
          style: {
            'text-align': 'center',
          },
        },
        {
          label: this.$t('Company'),
          colspan: 3,
          style: {
            'text-align': 'center',
          },
        },
      ]
      return headers
    },
    columns() {
      let headers = []
      if (!this.isMember) {
        headers.push({
          label: 'Status',
          field: 'paid',
          sortable: false,
        })
      }
      headers = [...headers, {
        label: 'Username',
        field: '_id',
        filterOptions: {
          enabled: true,
          placeholder: 'Search ...',
          formatValue(value) {
            return String(value).trim()
          },
        },
        sortable: false,
        width: '130px',
      },
      {
        label: 'Amount',
        field: 'amount',
        type: 'number',
      },
      {
        label: 'ValidAmount',
        field: 'validAmount',
        type: 'number',
        width: '80px',
      },
      {
        label: 'StackCount',
        field: 'stackCount',
        type: 'number',
        tdClass: 'black--text',
      },
      {
        label: 'W/L',
        field: 'memberWinLose',
        type: 'number',
        width: '80px',
      },
      {
        label: 'Comm',
        field: 'memberWinLoseCom',
        type: 'number',
      },
      {
        label: 'W/L Comm',
        field: 'memberTotalWinLoseCom',
        type: 'number',
        tdClass: this.bgTotalCom,
      },
      {
        label: 'W/L',
        field: 'agentWinLose',
        type: 'number',
        width: '80px',
      },
      {
        label: 'Comm',
        field: 'agentWinLoseCom',
        type: 'number',
      },
      {
        label: 'W/L Comm',
        field: 'agentTotalWinLoseCom',
        type: 'number',
        tdClass: this.bgTotalCom,
      },
      {
        label: 'W/L',
        field: 'companyWinLose',
        type: 'number',
        width: '80px',
      },
      {
        label: 'Comm',
        field: 'companyWinLoseCom',
        type: 'number',
      },
      {
        label: 'W/L Comm',
        field: 'companyTotalWinLoseCom',
        type: 'number',
        tdClass: this.bgTotalCom,
      }]
      return headers
    },
    FieldNumberLists() {
      return [
        "apiTotalWinLoseCom",
        "apiWinLoseCom",
        "apiWinLose",
        "companyTotalWinLoseCom",
        "companyWinLoseCom",
        "companyWinLose",
        "partnerTotalWinLoseCom",
        "partnerWinLoseCom",
        "partnerWinLose",
        "seniorTotalWinLoseCom",
        "seniorWinLoseCom",
        "seniorWinLose",
        "masterTotalWinLoseCom",
        "masterWinLoseCom",
        "masterWinLose",
        "agentTotalWinLoseCom",
        "agentWinLoseCom",
        "agentWinLose",
        "memberTotalWinLoseCom",
        "memberWinLoseCom",
        "memberWinLose",
        "grossComm",
        // "stackCount",
        "validAmount",
        "amount",
      ]
    },
  },
  mounted() {
    this.getDates()
  },
  methods: {
    IsReportUpline,
    DecimalFormat,
    DecimalFormatCustomFix,
    selectionChanged(selectedRows) {
      this.selectedRows = selectedRows
      this.selectedRowsTotal = selectedRows.length
    },
    async getDates() {
      this.$wait.start('loading-dates')
      try {
        await this.$emit('onGetDates')
      } finally {
        this.$wait.end('loading-dates')
      }
    },

    async getReportBillingByWeek(selectedDate) {
      this.$wait.start('loading-reports')

      await this.$emit('onGetData', {
        selectedDate,
        cb: () => {
          this.$wait.end('loading-reports')
        },
      })

      // try {
      //   await this.$emit('onGetData', {
      //     selectedDate,
      //   })
      // } finally {
      //   this.$wait.end('loading-reports')
      // }
    },

    async onConfirmPaid() {
      this.$wait.start('loading-button-confirm')
      try {
        await this.$emit('onConfirmPaid', {
          selectedRows: this.selectedRows,
          selectedDate: this.selectedDate,
        })
      } finally {
        this.$wait.end('loading-button-confirm')
      }
    },
    async onConfirmPending() {
      this.$wait.start('loading-button-confirm')
      try {
        await this.$emit('onConfirmPending', {
          selectedRows: this.selectedRows,
          selectedDate: this.selectedDate,
        })
      } finally {
        this.$wait.end('loading-button-confirm')
      }
    },

    onChangeDateSelected(selectedDate) {
      this.selectedDate = selectedDate
      this.getReportBillingByWeek(selectedDate)
    },

    onReload() {
      this.getReportBillingByWeek(this.selectedDate)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
