<template>
  <b-form-select
    v-model="selected"
    :options="datesOptions"
    @change="onChangeDateSelected"
  >
    <template #first>
      <b-form-select-option
        :value="null"
        disabled
      >
        -- {{ $t('Please select an dates') }} --
      </b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      initial: false,
      selected: null,
    }
  },
  computed: {
    datesOptions() {
      return this.dates.map(dateList => ({
        value: dateList.weekRef,
        text: dateList.currentWeek ? `${dateList.dateStr} (${this.$t('Pending')})` : dateList.dateStr,
        disabled: dateList.currentWeek,
      }))
    },
  },
  mounted() {
    if (!this.initial) {
      const selectFirstWithoutCurrentWeek = this.dates.findIndex(list => !list.currentWeek)
      this.selected = this.dates[selectFirstWithoutCurrentWeek].weekRef
      this.onChangeDateSelected(this.selected)

      this.initial = true
    }
  },
  methods: {
    onChangeDateSelected(selected) {
      this.$emit('onChangeDateSelected', selected)
    },
  },
}
</script>

<style>

</style>
